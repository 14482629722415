/* eslint-disable */
import 'regenerator-runtime/runtime';
import axios from 'axios';
import { showUserAlert, errorUtils } from './alerts';

export const updateSettings = async (data, type, id) => {
  try {
    const url = type === 'password' ? `/api/v1/update/password` : `/reviews/${id}`;

    const res = await axios({
      method: 'PATCH',
      url,
      data,
    });

    if (res.data.status === 'success') {
      showUserAlert('success', `${type.toUpperCase()} updated successfully!`);
      window.setTimeout(() => {
        location.assign('/');
      }, 1500);
    }
  } catch (err) {
    showUserAlert('error', err.response.data.message);
  }
};

// type is either 'user', or 'admin'
export const createUserRecord = async (data, type) => {
  try {
    const url = type === 'user' ? `/api/v1/user/signup` : `/api/v1/admin/signup`;
    const res = await axios({
      method: 'POST',
      url,
      data,
    });

    if (res.data.status === 'success') {
      showUserAlert('success', `${type.toUpperCase()} created successfully! Check your email for verification.`);
      if (type === 'user') {
        window.setTimeout(() => {
          location.assign('/');
        }, 5000);
      } else if (type === 'admin') {
        showUserAlert('success', `${type.toUpperCase()} user creation successfully! Check user email for verification.`);
        window.setTimeout(() => {
          location.assign('/');
        }, 1500);
      }
    }
  } catch (err) {
    let msg = err.response.data.message;
    let dateValidation = 'Cast to date failed for value "Invalid Date" (type Date) at path "dob"';
    msg.includes(dateValidation) ? (msg = msg.replace(dateValidation, 'Please enter a valid date of birth!')) : '';

    // Using Regex to match words in msg ending with a colon
    let regex = /(\w+(?: \w+)*):/gi;

    //  Manipulate error message with slice, split, join and replaceAll methods
    let validationMsg = msg.slice(23).split(',').join(' ').replaceAll(regex, '');

    if (msg.includes('Duplicate field value:')) {
      showUserAlert('error', msg);
    }

    if (msg.includes('User validation failed:')) {
      // msg = msg.slice(23).split(',').join(' ');
      showUserAlert('error', validationMsg);
    }
  }
};

export const sendPasswordResetLink = async (data, type) => {
  try {
    const url = type === 'reset' ? `/api/v1/user/forgotPassword` : '';

    const res = await axios({
      method: 'POST',
      url,
      data,
    });

    if (res.data.status === 'success') {
      showUserAlert('success', `${type.toUpperCase()} password link sent successfully!`);
      if (type === 'reset') {
        window.setTimeout(() => {
          location.assign('/');
        }, 1500);
      }
    }
  } catch (err) {
    showUserAlert('error', err.response.data.message);
  }
};

export const userPasswordReset = async (data, type) => {
  try {
    const token = window.location.href.split('/')[8];
    const url = type === 'passwordReset' ? `/api/v1/user/resetPassword/${token}` : '';

    const res = await axios({
      method: 'PATCH',
      url,
      data,
    });

    if (res.data.status === 'success') {
      showUserAlert('success', `${type.toUpperCase()} was successfully! Please log in again.`);
      if (type === 'passwordReset') {
        window.setTimeout(() => {
          location.assign(`/api/v1/user/signin`);
        }, 1500);
      } else {
        window.setTimeout(() => {
          location.assign('/');
        }, 1500);
      }
    }
  } catch (err) {
    showUserAlert('error', err.response.data.message);
  }
};
