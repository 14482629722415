// SET ALERTS TO PROVIDE MEANINGFUL INFORMATION TO USERS
//type is 'success' or 'error'
export const hideUserAlert = () => {
  const el = document.querySelector('.alert');
  if (el) el.parentElement.removeChild(el);
};
export const showUserAlert = (type, msg) => {
  hideUserAlert();
  // const markup = `<div class="alert alert--${type}">${msg}</div>`;
  const markup = `<div class="alert alert--${type} alert-dismissible fade show w-50" id="catchErrors" role="alert">
           ${
             type === 'error'
               ? `<svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:">
                <use xlink:href="#exclamation-triangle-fill" /></svg>`
               : `<svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:">
                <use xlink:href="#check-circle-fill" /></svg>`
           } ${msg}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>`;
  // const markup = `<div class="alert alert--${type} alert-dismissible fade show rounded-0" role="alert">${msg}

  // <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`;
  document.querySelector('.clientSideAlerts').insertAdjacentHTML('afterbegin', markup);

  window.setTimeout(hideUserAlert, 5000);
};

export const errorUtils = {
  getError: (error) => {
    let e = error;
    if (error.response) {
      e = error.response.data; // data, status, headers
      if (error.response.data && error.response.data.error) {
        e = error.response.data.error; // my app specific keys override
      }
    } else if (error.message) {
      e = error.message;
    } else {
      e = 'Unknown error occured';
    }
    return e;
  },
};
