// DOM ELEMENTS
const dom = {
  email: document.getElementById('email'),
  password: document.getElementById('password'),
  loginForm: document.getElementById('loginForm'),
  logOutBtn: document.querySelector('.logout'),
  join: document.getElementById('join'),
  registeradmin: document.getElementById('registeradmin'),
  changeUserPasswordForm: document.querySelector('.form-change-user-password'),
};

import { login, logout } from './login';
import { updateSettings, createUserRecord, sendPasswordResetLink, userPasswordReset } from './userAccountSettings';

import { showUserAlert } from './alerts';

let appBody = document.querySelector('.app');
// Import bootstrap javascript bundle as a whole
const bootstrap = require('bootstrap/dist/js/bootstrap.bundle');

{
  // SIMULATE CLOSE MODAL FROM ANOTHER

  const $modals = document.querySelectorAll('.modal');
  $modals.forEach((modal) => {
    modal.addEventListener('show.bs.modal', function () {
      $modals.forEach((_modal) => {
        let currentModal = bootstrap.Modal.getInstance(_modal);
        if (this != _modal && currentModal) currentModal.hide();
      });
    });
  });
}

{
  // LOGIN AND LOGOUT DELEGATION
  if (dom.email && dom.password && dom.loginForm) {
    dom.loginForm.addEventListener('submit', (e) => {
      e.preventDefault();

      const email = dom.email.value;
      const password = dom.password.value;

      login(email, password);
    });
  }

  if (dom.logOutBtn) dom.logOutBtn.addEventListener('pointerdown', logout);
}

{
  // Using AXIOS TO SUBMIT NEW REGISTRATION DATA
  if (dom.join) {
    dom.join.addEventListener('submit', async (e) => {
      e.preventDefault();
      document.querySelector('.join-cta').textContent = 'Submitting...';

      setTimeout(() => {
        document.querySelector('.join-cta').textContent = 'Submit';
      }, 3000);

      const fullName = document.getElementById('fullName').value;
      const dob = document.getElementById('dob').value;
      const genderArr = document.getElementsByName('gender');
      const email = document.getElementById('email').value;
      const phoneNumber = document.getElementById('phoneNumber').value;
      const address = document.getElementById('address').value;
      const password = document.getElementById('password').value;
      const confirmPassword = document.getElementById('confirmPassword').value;
      const signedConsent = document.getElementById('tnc-checkbox').value;

      let gender = '';

      for (let i = 0; i < genderArr.length; i++) {
        if (genderArr[i].checked) {
          gender = genderArr[i].value;
          break;
        }
      }

      await createUserRecord({ fullName, dob, gender, email, phoneNumber, address, password, confirmPassword, signedConsent }, 'user');
    });
  }
}

{
  // Using AXIOS TO SUBMIT NEW ADMIN REGISTRATION DATA
  if (dom.registeradmin) {
    dom.registeradmin.addEventListener('submit', async (e) => {
      e.preventDefault();
      document.querySelector('.registeradmin_cta').textContent = 'Submitting...';

      setTimeout(() => {
        document.querySelector('.registeradmin_cta').textContent = 'Submit';
      }, 3000);

      const role = document.getElementById('role').value;
      const fullName = document.getElementById('fullName').value;
      const dob = document.getElementById('dob').value;
      const genderArr = document.getElementsByName('gender');
      const email = document.getElementById('email').value;
      const phoneNumber = document.getElementById('phoneNumber').value;
      const address = document.getElementById('address').value;
      const password = document.getElementById('password').value;
      const confirmPassword = document.getElementById('confirmPassword').value;
      const signedConsent = document.getElementById('tnc-checkbox').value;

      let gender = '';

      for (let i = 0; i < genderArr.length; i++) {
        if (genderArr[i].checked) {
          gender = genderArr[i].value;
          break;
        }
      }

      await createUserRecord({ role, fullName, dob, gender, email, phoneNumber, address, password, confirmPassword, signedConsent }, 'admin');
    });
  }
}

{
  // USING AXIOS TO CHANGE USER PASSWORD
  if (dom.changeUserPasswordForm)
    dom.changeUserPasswordForm.addEventListener('submit', async (e) => {
      e.preventDefault();
      document.querySelector('.btn--submit-password').innerHTML = `<i class="bi bi-cloud-upload"></i> Updating...`;

      const currentPassword = document.getElementById('currentPassword').value;
      const password = document.getElementById('changePassword').value;
      const confirmPassword = document.getElementById('changeConfirmPassword').value;

      if (currentPassword === '' || password === '' || confirmPassword === '') {
        document.querySelector('.btn--submit-password').innerHTML = `<i class="bi bi-cloud-upload"></i> Submit`;
        return showUserAlert('error', 'Password fields cannot be empty!');
      }
      if (password != confirmPassword) {
        document.querySelector('.btn--submit-password').innerHTML = `<i class="bi bi-cloud-upload"></i> Submit`;
        return showUserAlert('error', 'New password and Confirm Password do not match!');
      }
      await updateSettings({ currentPassword, password, confirmPassword }, 'password');

      document.querySelector('.btn--submit-password').innerHTML = `<i class="bi bi-cloud-upload"></i> Submit`;
      // document.getElementById('currentPassword').value = '';
      // document.getElementById('changePassword').value = '';
      // document.getElementById('changeConfirmPassword').value = '';
    });
}

{
  // USE AXIOS TO PROCESS AND SEND PASSWORD RESET LINK
  const forgottenPassword = document.getElementById('forgottenPassword');
  if (forgottenPassword) {
    forgottenPassword.addEventListener('submit', async (e) => {
      e.preventDefault();
      const email = document.getElementById('email').value;
      if (!email) {
        return showUserAlert('error', 'Please enter your registered email address!');
      }
      document.querySelector('.submit__cta').textContent = 'Sending please wait...';

      await sendPasswordResetLink({ email }, 'reset');

      document.getElementById('email').value = '';
      document.querySelector('.submit__cta').textContent = 'Send reset instructions';
    });
  }
}

{
  // IF PASSWORD RESET FORM IS OPEN WITH A RESET TOKEN, PROCESS PASSWORD RESET
  const processPasswordReset = document.getElementById('passwordReset');
  if (processPasswordReset) {
    processPasswordReset.addEventListener('submit', async (e) => {
      e.preventDefault();
      document.querySelector('.submit__cta').textContent = 'Submitting please wait...';

      const password = document.getElementById('password').value;
      const confirmPassword = document.getElementById('confirmPassword').value;
      await userPasswordReset({ password, confirmPassword }, 'passwordReset');

      document.getElementById('password').value = '';
      document.getElementById('confirmPassword').value = '';
      document.querySelector('.submit__cta').textContent = 'Submit';
    });
  }
}

// INITIALIZE/ENABLE BOOTSTRAP TOOLTIP FOR ALL TOOLTIP ELEMENTS
// const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
// const tooltipList = tooltipTriggerList.map(function (tooltipTriggerElement) {
//   return new bootstrap.Tooltip(tooltipTriggerElement);
// });

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));

// ENABLE POPOVERS
const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
const popoverList = [...popoverTriggerList].map((popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl));

{
  // Go back to top
  const backToTopBtn = document.getElementById('backToTop');
  const appTop = document.getElementById('app');
  const appBody = document.querySelector('.app');
  const navigation = document.querySelector('.nav-top');
  backToTopBtn.addEventListener('pointerdown', () => {
    appTop.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  });

  const backToTop = () => {
    var scrollY = window.scrollY;
    var navTop = 110;
    // console.log(scrollY);
    // window.scrollY > navTop ? dom.backToTopBtn.classList.add('show') :dom.backToTopBtn.classList.remove('show');
    window.scrollY > 90 ? (navigation.style.transform = 'translateY(-20px)') : (navigation.style.transform = 'translateY(0)');
    if (window.scrollY > navTop) {
      backToTopBtn.classList.add('show');
      navigation.classList.add('fixed-top-navbar');
      appBody.classList.add('padding-top');

      // On scroll down add a fixed class to aside navigation
      document.querySelector('aside').classList.add('aside-fixed');
    } else if (window.scrollY < navTop) {
      backToTopBtn.classList.remove('show');
      navigation.classList.remove('fixed-top-navbar');
      appBody.classList.remove('padding-top');
      document.querySelector('aside').classList.remove('aside-fixed');
    }
  };
  window.addEventListener('scroll', backToTop);
}

{
  // GDPR
  let jsGdpr = document.getElementById('js__gdpr');
  let jsCover = document.getElementById('jsCover');

  // Prevent display of Cookie and Privacy notice banner once user acknowledges it
  if (localStorage.gdprAccepted) {
    jsGdpr.classList.add('gdprRemove');
    jsCover.classList.add('gdprRemove');
  }

  var gdprNotice = 'User Closed Notification';
  document.getElementById('js__close__gdpr').addEventListener('pointerdown', () => {
    // Reverse Animate GDPR
    const gdpr = document.querySelector('.gdpr');
    var p = -2;
    var callBackInterval = setInterval(reverseAnimate, 10);

    function reverseAnimate() {
      if (p === -235) {
        clearInterval(callBackInterval);
      } else {
        p = p - 1; //p++;
        gdpr.style.bottom = p + 'px';
      }
    }

    setTimeout(() => {
      jsGdpr.classList.remove('gdpr');
      jsGdpr.classList.add('gdprRemove');
      jsCover.classList.add('gdprRemove');
      localStorage.setItem('gdprAccepted', gdprNotice);
    }, 1000);
  });

  // Animate GDPR
  const gdpr = document.querySelector('.gdpr');
  var p = -180;
  var callBackInterval = setInterval(animate, 10);

  function animate() {
    if (p === -2) {
      clearInterval(callBackInterval);
    } else {
      p = p + 1; //p++;
      gdpr.style.bottom = p + 'px';
    }
  }
}

{
  // Make Selected Menu Active
  // Requires setting home page menu as active class
  // In Node Js this is redundant due to refresh and with ejs templating
  // you can set active class based on rendered page title
  // <% if (title === 'Sign Up') { %> class="active" <% } %> on menu items
  /*
  const menuItems = Array.from(document.querySelectorAll('.sidebar a:not(:last-child)'));
  for (const selected of menuItems) {
    selected.addEventListener('pointerdown', (e) => {
      document.querySelector('.sidebar a.active').classList.remove('active');
      selected.classList.add('active');
    });
  }
  */
}

{
  // Open and Close Menu
  const sideMenu = document.querySelector('aside');
  const menuBtn = document.querySelector('#menu-btn');
  const closeBtn = document.querySelector('#close-btn');
  const asideTop = document.querySelector('aside .top');

  if (menuBtn) {
    menuBtn.addEventListener('pointerdown', (e) => {
      // console.log('menu button');
      sideMenu.classList.remove('hide-aside-menu');
      sideMenu.classList.add('show-aside-menu');
      // sideMenu.style.display = 'block';
      asideTop.classList.add('show-close');
    });
  }

  if (closeBtn) {
    closeBtn.addEventListener('pointerdown', (e) => {
      sideMenu.classList.add('hide-aside-menu');
      sideMenu.classList.remove('show-aside-menu');
      // sideMenu.style.display = 'none';
      asideTop.classList.remove('show-close');
    });
  }
}

{
  // Show Hide Password Text
  const showHidePassword = Array.from(document.querySelectorAll('.show-hide-password'));
  const showHideConfirmPassword = Array.from(document.querySelectorAll('.show-hide-confirm-password'));
  const inputPassword = Array.from(document.querySelectorAll('.input-password'));
  const inputConfirmPassword = Array.from(document.querySelectorAll('.input-confirm-password'));
  const inputCurrentPassword = document.querySelector('.input-current-password');
  const showHideCurrentPassword = document.querySelector('.show-hide-current-password');

  if (showHidePassword) {
    for (const cur of showHidePassword) {
      cur.addEventListener('pointerdown', (e) => {
        if (cur.textContent === 'visibility_off') {
          cur.textContent = 'visibility';
          for (const type of inputPassword) {
            type.setAttribute('type', 'text');
          }
        } else {
          cur.textContent = 'visibility_off';
          for (const type of inputPassword) {
            type.setAttribute('type', 'password');
          }
        }
      });
    }
  }

  if (showHideConfirmPassword) {
    for (const cur of showHideConfirmPassword) {
      cur.addEventListener('pointerdown', (e) => {
        if (cur.textContent === 'visibility_off') {
          cur.textContent = 'visibility';
          for (const type of inputConfirmPassword) {
            type.setAttribute('type', 'text');
          }
        } else {
          cur.textContent = 'visibility_off';
          for (const type of inputConfirmPassword) {
            type.setAttribute('type', 'password');
          }
        }
      });
    }
  }

  if (showHideCurrentPassword) {
    showHideCurrentPassword.addEventListener('pointerdown', (e) => {
      if (showHideCurrentPassword.textContent === 'visibility_off') {
        showHideCurrentPassword.textContent = 'visibility';
        document.querySelector('.input-current-password').setAttribute('type', 'text');
      } else {
        showHideCurrentPassword.textContent = 'visibility_off';
        document.querySelector('.input-current-password').setAttribute('type', 'password');
      }
    });
  }
}

{
  // SHOW HIDE ADMIN OPTIONS LIST
  const tabContent = document.querySelector('.tabContent');
  const optionsCheckBox = Array.from(document.querySelectorAll('.options-checkbox'));
  const optionsList = Array.from(document.querySelectorAll('.options-list'));
  if (tabContent) {
    for (const checkBox of optionsCheckBox) {
      checkBox.setAttribute('checked', 'checked');
      checkBox.addEventListener('change', (e) => {
        for (const list of optionsList) {
          if (e.target.checked === false) {
            // console.log(e.target.checked);
            list.classList.add('d-none');
          } else {
            // console.log(e.target.checked);
            list.classList.remove('d-none');
          }
        }
      });
    }
  }
}

{
  // MULTI STEP FORM
  const prevBtn = document.querySelectorAll('.btn-prev');
  const nextBtn = document.querySelectorAll('.btn-next');
  const progress = document.getElementById('progress');
  const formStep = document.querySelectorAll('.form-step');
  const progressStep = document.querySelectorAll('.progress-step');
  let formStepNum = 0;

  if (formStep) {
    nextBtn.forEach((btn) => {
      btn.addEventListener('click', () => {
        formStepNum++;
        updateFormStep();
        updateProgressBar();
      });
    });
  }
  if (formStep) {
    prevBtn.forEach((btn) => {
      btn.addEventListener('click', () => {
        formStepNum--;
        updateFormStep();
        updateProgressBar();
      });
    });
  }

  function updateFormStep() {
    formStep.forEach((formStep) => {
      formStep.classList.contains('form-step-active') ? formStep.classList.remove('form-step-active') : '';
    });
    formStep[formStepNum].classList.add('form-step-active');
  }

  function updateProgressBar() {
    progressStep.forEach((step, index) => {
      if (index < formStepNum + 1) {
        step.classList.add('progress-step-active');
      } else {
        step.classList.remove('progress-step-active');
      }
    });

    const progressActive = document.querySelectorAll('.progress-step-active');
    progress.style.width = ((progressActive.length - 1) / (progressStep.length - 1)) * 100 + '%';
  }
}

{
  // Select all input elements
  // User blur event to check when a user shifts focus from empty field

  const userInput = Array.from(document.querySelectorAll('input'));
  const userSelect = Array.from(document.querySelectorAll('select'));
  const buttonSubmit = document.querySelector('.submit__cta');
  const btnNext = document.querySelector('.btn-next');
  // const userInput = Array.from(document.querySelectorAll('input'));
  // const userSelect = Array.from(document.querySelectorAll('select'));
  // const buttonSubmit = Array.from(document.querySelectorAll('.submit__cta'));

  if (userInput || btnNext) {
    for (const element of userInput) {
      element.addEventListener('keyup', (e) => {
        if (element.value === '') {
          // const originalAttribute = [];
          // const name = element.getAttribute('name');
          // const nameStr = name.charAt(0).toUpperCase() + name.slice(1);
          element.style.border = '1px solid red';
          element.classList.remove('is-valid');
          element.classList.add('is-invalid');
          iconWrapperBorderLeft('is-invalid');
          element.classList.add('placeholder-class');
        } else if (element.value != '') {
          const patterns = {
            email: /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/i,
            currentPassword: /^[\w@-]{8,15}$/, // THIS IS CASE SENSITIVE WITHOUT i
            password: /^[\w@-]{8,15}$/, // THIS IS CASE SENSITIVE WITHOUT i
            confirmPassword: /^[\w@-]{8,15}$/, // THIS IS CASE SENSITIVE WITHOUT i
            phoneNumber: /^[0-9]{11}$/,
            address: /^/,
            fullName: /^/,
            search: /^/,
          };

          validate(e.target, patterns[e.target.attributes.name.value]);

          function validate(field, regex) {
            // console.log(field);
            // console.log(e.target.attributes.name.value);
            if (regex.test(field.value)) {
              element.style.border = '1px solid green';
              element.classList.remove('is-invalid');
              element.classList.add('is-valid');
              iconWrapperBorderLeft('is-valid');
            } else {
              // field.value === '';
              // const originalAttribute = [];
              // const name = element.getAttribute('name');
              // const nameStr = name.charAt(0).toUpperCase() + name.slice(1);
              element.style.border = '1px solid red';
              element.classList.remove('is-valid');
              // field.classList.add('is-invalid'); // this is correct usage with field
              element.classList.add('is-invalid');
              iconWrapperBorderLeft('is-invalid');
              element.classList.add('placeholder-class');
            }
          }
        }

        function iconWrapperBorderLeft(isVal) {
          const nextButton = document.querySelector('.btn-next');
          if (isVal === 'is-valid') {
            e.target.parentNode.childNodes[3].classList.add('isValid');
            e.target.parentNode.childNodes[3].classList.remove('isInvalid');

            if (nextButton) {
              nextButton.classList.remove('disabled');
            }
          }

          if (isVal === 'is-invalid') {
            e.target.parentNode.childNodes[3].classList.add('isInvalid');
            e.target.parentNode.childNodes[3].classList.remove('isValid');

            if (nextButton) {
              nextButton.classList.add('disabled');
            }
          }
        }
      });
    }
  }

  // if (userSelect) {
  //   for (const element of userSelect) {
  //     if (element.value != '') {
  //       element.style.border = '1px solid green';
  //       element.classList.remove('is-invalid');
  //       element.classList.add('is-valid');
  //     } else {
  //       element.style.border = '1px solid red';
  //       element.classList.add('is-invalid');
  //     }

  //     element.addEventListener('change', (e) => {
  //       if (e) {
  //         element.style.border = '1px solid green';
  //         element.classList.remove('is-invalid');
  //         element.classList.add('is-valid');
  //       }
  //     });
  //   }
  // }
}

{
  // CHECK FOR is-valid and is-invalid CLASSES
  // and disable/enable submit button when a field is invalid/valid
  // Immediately Invoked Function Expression
  (() => {
    let invalid = [];
    const userInput = Array.from(document.querySelectorAll('input'));
    const buttonSubmit = document.querySelector('.submit__cta');
    const formError = document.getElementById('formError');

    if (buttonSubmit) {
      for (const input of userInput) {
        document.addEventListener('keyup', (e) => {
          for (const cur of userInput) {
            if (cur.classList.contains('is-invalid')) {
              formError.style.display = 'block';
              formError.style.backgroundColor = '#c7313f';
              invalid.push(cur);
              if (invalid.length >= 1) {
                // buttonSubmit.disabled = true;
                formError.textContent = 'Invalid or missing field!';
                break;
              }
            } else {
              // buttonSubmit.disabled = false;
              document.getElementById('formError').textContent = '';
              formError.style.display = 'none';
            }
          }
        });
      }
    }
  })();
}

{
  // Disable form submit button
  // DELEGATION
  const tncCheckbox = document.querySelector('.tnc-Check');
  const buttonSubmit = document.querySelector('.submit__cta');

  if (tncCheckbox && buttonSubmit) {
    window.addEventListener('load', function () {
      // Enable or Disable form submit button
      buttonSubmit.disabled = true;
      tncCheckbox.addEventListener('change', function () {
        var checkboxChecked = this.checked;
        if (checkboxChecked) {
          buttonSubmit.disabled = false;
          //console.log('checked');
        } else {
          buttonSubmit.disabled = true;
          //console.log('unchecked');
        }
      });
    });
  }
}

{
  // Show relevant State Based on Geopolitical Zone selection
  const geoPoliticalZone = document.getElementById('geoPoliticalZone');
  const state = document.getElementById('state');
  const zoneArr = Array.from(document.querySelectorAll('.zoned'));

  if (geoPoliticalZone) {
    geoPoliticalZone.addEventListener('change', (e) => {
      state.value = '- Select State -';
      state.classList.add('is-invalid');
      state.classList.remove('is-valid');
      state.style.border = '1px solid red';
      // Class name with value of each zone separated by hyphen has been added to each zone's state list
      // Now when a zone is selected we can use the value to target listed states under the zone
      let zone = geoPoliticalZone.value.split(' ').join('-').toLowerCase();
      for (const cur of zoneArr) {
        if (!cur.classList.contains('d-none')) {
          cur.classList.add('d-none');
        }

        if (cur.classList.contains(zone)) {
          cur.classList.remove('d-none');
        }
      }
    });
  }
}

{
  // Close any Bootstrap alert with 'auto-dismissible-alert' class
  // The following line results to an object - Alerts: {}
  const closeAlerts = bootstrap.Alert.getOrCreateInstance('.auto-dismissible-alert');
  // console.log(closeAlerts);

  // Execute this line only when the object exists and is not empty
  if (closeAlerts && Object.keys(closeAlerts).length > 0) {
    setTimeout(() => {
      closeAlerts.close();
    }, 5000);
  }
}

{
  // ADD OCCUPATION NAMES ON THE FLY
  const addOccupationName = document.getElementById('addOccupationName');
  const removeOccupationName = document.getElementById('removeOccupationName');
  const occupationBlock = document.getElementById('occupationBlock');
  const inputCount = Array.from(document.querySelectorAll('.occupation_name'));

  if (inputCount) {
    let pNum = inputCount.length;
  }
  // let pNum = 1;

  const isOccupationNameAdded = () => {
    if (occupationBlock) {
      Array.from(document.querySelectorAll('.occupationNameBlock__item')).length === 0 ? (removeOccupationName.disabled = true) : (removeOccupationName.disabled = false);
      // Array.from(document.querySelectorAll('.paragraphBlock__item')).length === 0 ? removeParagraph.classList.add('d-none') : removeParagraph.classList.remove('d-none');
    }
  };
  isOccupationNameAdded();

  if (addOccupationName) {
    addOccupationName.addEventListener('pointerdown', (e) => {
      if (e.target) {
        const newDivElement = document.createElement('div');
        newDivElement.classList.add('occupationNameBlock__item');
        pNum++;
        pNum === 10 ? (addOccupationName.disabled = true) : (addOccupationName.disabled = false);
        // pNum === 10 ? addParagraph.classList.add('d-none') : addParagraph.classList.remove('d-none');

        const newOccupation = `<div class="input-group mt-3 mb-3"><label for="occupation_list" class="input-group-text">Occupation Title</label><input type="text" class="form-control occupation_name" name="occupation_name_${pNum}" placeholder="Occupation name"></div>`;
        newDivElement.insertAdjacentHTML('beforeend', newOccupation);
        occupationBlock.appendChild(newDivElement);

        // const pBlockItems = Array.from(document.querySelectorAll('.paragraphBlock__item'));
        // console.log(pBlockItems);
        isOccupationNameAdded();
      }
    });
  }

  if (removeOccupationName) {
    removeOccupationName.addEventListener('click', (e) => {
      if (e.target) {
        pNum--;
        pNum < 10 ? (addOccupationName.disabled = false) : (addOccupationName.disabled = true);
        const occupationBlockItems = Array.from(document.querySelectorAll('.occupationNameBlock__item'));
        if (occupationBlockItems) {
          const numItems = occupationBlockItems.length;
          const removeIndex = numItems - 1;
          const elementToRemove = occupationBlockItems[removeIndex];
          elementToRemove.remove();
          // console.log(occupationBlockItems);
          isOccupationNameAdded();
        }
      }
    });
  }
}

{
  // Read more
  const readControl = document.querySelector('.read-control');
  const read = document.querySelector('.read');
  if (readControl) {
    readControl.addEventListener('pointerdown', (e) => {
      if (read.classList.contains('d-none')) {
        read.classList.toggle('d-none');
        readControl.textContent = 'unfold_less_double';
        readControl.setAttribute('data-bs-original-title', 'Read Less');
      } else {
        read.classList.toggle('d-none');
        readControl.textContent = 'unfold_more_double';
        readControl.setAttribute('data-bs-original-title', 'Read More');
      }
    });
  }
}

{
  // Welcome PopUp message

  // Function to stimulate click on popUpTrigger
  function welcomePopup() {
    const popUp = new MouseEvent('click', {
      view: window,
      bubbles: true,
    });
    const popUpTrigger = document.getElementById('popUpTrigger');
    popUpTrigger.dispatchEvent(popUp);
  }

  // Page load event listener
  window.addEventListener('load', (e) => {
    // Local Storage
    const getStarted = localStorage.getItem('getStarted');
    const doNotShowPopup = localStorage.getItem('doNotShowPopup');
    if (!getStarted && !doNotShowPopup) {
      setTimeout(() => {
        welcomePopup();
      }, 3000);
    } else if (getStarted && !doNotShowPopup) {
      setTimeout(() => {
        welcomePopup();
      }, 3000);
    }
  });

  // Add Local Storage for clicking on X Don't show this again

  const app = document.getElementById('app');
  app.addEventListener('click', (e) => {
    if (e.target.closest('.welcome-pop-up-trigger')) {
      localStorage.setItem('getStarted', 'userClickedGetStarted'); // Key: 'Value' pair. Value can be a variable
      setTimeout(() => {
        localStorage.removeItem('doNotShowPopup');
      }, 1000);
    }

    if (e.target.closest('.welcome-btnClose')) {
      localStorage.setItem('doNotShowPopup', 'userClickedDoNotShowPopup'); // Key: 'Value' pair. Value can be a variable
    }
  });
}
